<template>
  <q-page>
    <div>Bienvenido a BH Proyectos</div>
  </q-page>
</template>

<script>
import HomeServices from '../Services/HomeServices';

const homeServices = new HomeServices();
export default {
name: "home",
  mounted () {
    homeServices.validarApi();
  },
}
</script>