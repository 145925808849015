import axiosUtils from "../Services/Requests/AxiosUtils";
import axios from "../Services/Requests/AxiosConfig";

export default {
    validarApi() {
        return new Promise((resolve, reject) => {
            let params = { params: axiosUtils.getParams() };
            axios.get("home/validarApi", params).then(resp => {
                let data = resp.data;
                if (data.codigo !== 200)
                    throw data.mensaje;
                resolve();
            }).catch(err => axiosUtils.axiosCatch(err, error => reject(error)));
        }).catch(err => axiosUtils.axiosCatch(err));
    }
}